a {
  color: #eeffff;
}

body {
  background-color: #263238;
  font-family: "Fira Code", monospace;
  color: #eeffff;
  margin: 0 auto;
  padding: 0;
}

.content {
  min-width: 750px;
  max-width: 50%;
  min-height: 600px;
  height: calc(100vh - 46px);
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
}

.post {
  background-color: #212b30;
  font-size: 16px;
  padding: 1%;
  margin: 1%;
}

nav {
  background-color: #1c2529;
  overflow: hidden;
  top: 0px;
  width: 100%;
  height: 43px;
}

nav a {
  float: left;
  color: #eeffff;
  text-align: center;
  padding: 10px;
  font-size: 20px;
  text-decoration: none;
  background-color: #1c2529;
}

nav a:hover {
  color: #82aaff;
}

nav a.active {
  background-color: #212b30;
}

.linkActive {
  background-color: #212b30;
}

code {
  background-color: #1c2529;
  display: block;
  padding: 10px;
}

.learning {
  background-color: #212b30;
  padding: 10px;
  padding-top: 5px;
  font-size: 18px;
}

.learning ul {
  list-style-type: none;
}

.bioTop {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0 auto;
}

.profilePic {
  width: 40%;
  margin: 5px;
  box-shadow: 0px 0px 150px 8px rgba(130, 170, 255, 0.15);
}

.contactInfo {
  font-size: larger;
  margin: 5%;
}

a.GHLinkLogo {
  padding: 0;
  height: 100%;
  float: right;
  margin-right: 1%;
}

img.GHLinkLogo {
  max-height: 70%;
  object-fit: contain;
  padding-top: 7px;
  right: 1%;
}

@media only screen and (max-width: 750px) {
  h2,
  h4,
  h5 {
    margin: 0;
    margin-top: 1vh;
    margin-bottom: 1vh;
  }

  .content {
    min-height: 600px;
    min-width: 200px;
    max-width: 100%;
    width: 100%;
  }

  .center {
    width: 96%;
    margin: 0 auto;
  }

  .profilePic {
    padding: 0;
    margin: 0 auto;
  }

  .bioTop {
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    gap: 3vh;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .contactInfo {
    margin: 0;
  }

  .post {
    padding: 2%;
    margin-bottom: 3%;
  }

  .post:first-child{
    margin-top: 3%;

  }

  .post:last-child {
    margin-bottom: 0px;
  }

}

@media only screen and (max-width: 300px) {
  .contactInfo {
    padding: 3vw;
  }
  
  .post {
    margin: 0.5%;
    padding-left: 3%;
    padding-right: 3%;
    margin-bottom: 4%;
  }

  .post:first-child{
    margin-top: 3%;

  }

  .post:last-child {
    margin-bottom: 0px;
  }
  

  p {
    margin: 0px;
    margin-top: 2vh;
  }

  p:last-child {
    margin-top: 0.75vh;
  }
}